/** @jsxImportSource theme-ui **/
import * as React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/index/hero"
import Testimonials from "../components/index/testimonials"
import Galaxy from "../components/index/galaxy"
import MockUp from "../components/index/mockUp"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const IndexPage = () => (
  <Layout>
    <>
      <Seo title="Home" />
      <Hero />
      <MockUp />
      <Testimonials />
      <Galaxy />
      {/* <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
      <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
      <Link to="/using-dsg">Go to "Using DSG"</Link>
    </p> */}
    </>
  </Layout>
)

export default IndexPage
