/** @jsx jsx */
import { jsx } from "theme-ui"
import { HeroImageWrapper, CasouselWrapper, Section } from "./index.css"
// import { UpDown } from "../animations"
// import { StaticImage } from "gatsby-plugin-image"

// import { CarouselComponent } from "./carousel"

const Hero = () => {
  return (
    <HeroImageWrapper>
      {/* <UpDown> */}
      <Section style={{ display: "grid" }}>
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <img
          style={{
            gridArea: "1/1",
          }}
          src="https://res.cloudinary.com/averydante/image/upload/v1703276127/websiteBG.png"
          alt="The Great Pyramid of Kehran on Merkadah"
        />
        <div
          sx={{
            gridArea: `1/1`,
            position: `relative`,
            placeItems: `center`,
            display: `grid`,
          }}
        >
          {/* Any content here will be centered in the component */}
          {/* <CasouselWrapper>
              <CarouselComponent />
            </CasouselWrapper> */}
        </div>
      </Section>
      {/* </UpDown> */}
    </HeroImageWrapper>
  )
}

export default Hero
