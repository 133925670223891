/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { alturahTheme } from "../../gatsby-plugin-theme-ui"
import {
  GalaxyWrapper,
  IndexHeader,
  IndexSubHeader,
  IndexText,
  Testimonial,
  TestimonialsWrapper,
} from "./index.css"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Galaxy = () => {
  return (
    <>
      <IndexHeader>The Galaxy</IndexHeader>
      <IndexSubHeader>An Immersive Universe</IndexSubHeader>
      <StaticQuery
        query={graphql`
          {
            file(relativePath: { eq: "galaxyTrans.png" }) {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  transformOptions: { fit: CONTAIN }
                  layout: FULL_WIDTH
                )
              }
            }
          }
        `}
        render={data => (
          <GalaxyWrapper>
            <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} />
          </GalaxyWrapper>
        )}
      />

      <TestimonialsWrapper>
        <Testimonial>
          “Give me a place where I can build and my people shall plant the seed
          that grows the world” <br /> <br />
          <strong> - The Most Honorable Kehran</strong>
        </Testimonial>

        <div
          sx={{
            width: [`full`, `full`, `90%`, `90%`],
            alignContent: `center`,
            backgroundColor: `#00000060`,
            height: `fit-content`,
            alignSelf: `center`,
            paddingX: [`20px`, `2rem`],
            paddingY: [`30px`, `3rem`],
            border: `1px solid ${alturahTheme.colors.merkadah.red}`,
            borderRadius: `200px / 100px `,
            marginY: [`2rem`, `2rem`, 0, 0],
            "&:hover": {
              transform: `translateY(-5px)`,
              transition: `.5s`,
              border: `2px solid ${alturahTheme.colors.merkadah.gold}`,
            },
          }}
        >
          <p
            sx={{
              textAlign: `center`,
              fontFamily: `Pragmatica Extended`,
              color: alturahTheme.colors.merkadah.white,
              fontSize: [`1.5rem`, `1.5rem`, `2rem`, `2rem`],
              lineHeight: [`30px`, `40px`],
              marginTop: 12,
            }}
          >
            The galaxy of Alturah consists of noble worlds, fringe planets,
            interstellar gates, and the remnants of past civilizations.
            Experience interplanetary conflict, a long-lost ancient history,
            socioeconomic disparity, and  capitalism on a galactic scale.
          </p>
        </div>
      </TestimonialsWrapper>
    </>
  )
}
export default Galaxy
