/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import {
  Testimonial,
  IndexHeader,
  TestimonialsWrapper,
  IndexSubHeader,
  TuraeonTitle,
} from "./index.css"
import { alturahTheme, shadow } from "../../gatsby-plugin-theme-ui"
const Testimonials = () => {
  return (
    <div sx={{ padding: 25 }}>
      <IndexHeader>Beta Readers</IndexHeader>
      <IndexSubHeader>See what readers are saying</IndexSubHeader>
      <TestimonialsWrapper>
        <Testimonial>
          “This classic space opera and urban fantasy adventure blends{" "}
          <strong>Star Wars</strong> with HBO's <strong>The Wire</strong>.”
        </Testimonial>

        <Testimonial>
          “I have been waiting for a sci-fi story like this. A story with some
          <strong>black excellence</strong>and proper
          <strong>cultural vernacular</strong>.”
        </Testimonial>
        <Testimonial>
          “What? This is the first black <strong>crypto scifi story</strong>?!”
        </Testimonial>
      </TestimonialsWrapper>
    </div>
  )
}
export default Testimonials
