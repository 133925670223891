import styled from "@emotion/styled"
import {
  alturahTheme,
  shadow,
  device,
} from "../../gatsby-plugin-theme-ui/index"

export const AlturahSubTitle = styled.h1`
  margin-top: 3rem;
  font-family: Copperplate;
  color: ${alturahTheme.colors.merkadah.yellow};
  ${shadow.P1}
  font-size: 2rem;
  text-align: center;
`
export const HeroImageWrapper = styled.div`
  width: 100%;
  z-index: 0;
`
export const Section = styled.div`
  display: grid;
  width: 100%;
`

export const CasouselWrapper = styled.div`
  width: 75%;
  margin-top: 8rem;
  @media ${device.mobileS} {
    margin-left: 0px;
    width: 100%;
    padding: 1rem;
  }
  @media ${device.mobileL} {
    margin-left: 0px;
    width: 100%;
    padding: 2rem;
  }
`

export const TuraeonTitle = styled.h1`
  text-align: center;
  font-family: Azonix;
  font-size: 3rem;
  color: ${alturahTheme.colors.merkadah.yellow};
  ${shadow.H1}
  @media ${device.mobileS} {
    font-size: 2rem;
  }
  @media ${device.mobileL} {
    font-size: 2.5rem;
  }
`
export const TestimonialsWrapper = styled.div`
  margin: 0 auto;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  line-height: 30px;
  @media ${device.mobileS} {
    width: 90%;
    flex-direction: column;
    margin: 1rem auto;
  }
  @media ${device.mobileL} {
    flex-direction: column;
    margin: 1rem auto;
  }
`
export const Testimonial = styled.p`
  font-family: LTC Kennerley Pro;
  font-size: 1.5rem;
  text-align: center;
  color: ${alturahTheme.colors.merkadah.white};
  padding: 1rem;
  line-height: 40px;
  text-transform: capitalize;
  @media ${device.mobileS} {
    font-size: 1rem;
  }
  @media ${device.mobileL} {
    font-size: 1rem;
  }
`
export const IndexHeader = styled.h1`
  color: ${alturahTheme.colors.merkadah.white};
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  margin: 4rem 1rem 0.5rem 1rem;
  font-family: Ethnocentric;
`
export const IndexSubHeader = styled.h2`
  color: ${alturahTheme.colors.merkadah.red};
  text-align: center;
  font-family: Copperplate;
`
export const GalaxyWrapper = styled.div`
  width: 100%;
  margin: 1rem 0 1rem 0;
`

export const IndexText = styled.div`
  width: 80%;
  text-align: center;
`
