/** @jsx jsx */
import { jsx } from "theme-ui"
import { alturahTheme, shadow } from "../../gatsby-plugin-theme-ui"
import { MockUpImage } from "../../images/images"
import {
  AlturahSubTitle,
  TuraeonTitle,
} from "./index.css"
import {
  PatreonButton,
  PatreonLogo,
  PatreonButtonWrapper,
  PatreonLink,
} from "../Header/header.css"
import { FaPatreon } from "react-icons/fa"
const MockUp = () => {
  return (
    <div sx={{ padding: 25 }}>
      <TuraeonTitle>
        <h2
          sx={{
            color: alturahTheme.colors.merkadah.red,
            fontFamily: `Ethnocentric`,
            fontSize: `2rem`,
          }}
        >
          Book One <br />
        </h2>
        <h1
          sx={{
            color: alturahTheme.colors.merkadah.white,
            fontFamily: `Azonix`,
            fontSize: [`2.5rem`, `2.5rem`, `3rem`, `3rem`],
            textAlign: `center`,
            marginY: `1rem`,
          }}
        >
          The Turaeon Report
        </h1>
      </TuraeonTitle>
      <div
        sx={{
          width: `60%`,
          margin: `0 auto`,
          padding: 3,
        }}
      >
        <MockUpImage />
      </div>
      <div
        sx={{
          //   width: [`full`, `full`, `90%`, `40%`],
          alignContent: `center`,

          backgroundColor: `${alturahTheme.colors.merkadah.black}`,
          height: `fit-content`,
          alignSelf: `center`,
          paddingX: [`30px`, `2rem`],
          paddingY: [`40px`, `3rem`],
          border: `1px solid ${alturahTheme.colors.merkadah.yellow}`,
          borderRadius: `200px / 20px`,
          marginY: `2rem`,
          "&:hover": {
            transform: `translateY(-5px)`,
            transition: `.5s`,
            border: `2px solid ${alturahTheme.colors.merkadah.red}`,
            backgroundColor: `#000000`,
          },
        }}
      >
        <p
          sx={{
            textAlign: `center`,
            fontFamily: `Pragmatica Extended`,
            color: alturahTheme.colors.merkadah.white,
            textShadow: `.5px .5px 1px ${alturahTheme.colors.merkadah.black}`,
            fontSize: [`1.5rem`, `1.5rem`, `2rem`, `1.75rem`],
            lineHeight: [`30px`, `40px`],
            marginTop: 10,
          }}
        >
          D.L. Hines&rsquo; new series is a saga of epic fantasy, urban crime,
          melodrama, and more. One of heroism, adventure, political intrigue,
          and galactic war!
        </p>
        <AlturahSubTitle>Coming out in 2023!</AlturahSubTitle>
        <PatreonLink
          href="https://www.patreon.com/bePatron?u=69346058"
          data-patreon-widget-type="become-patron-button"
        >
          <PatreonButtonWrapper>
            <PatreonButton>
              <PatreonLogo>
                <FaPatreon />
              </PatreonLogo>
              Become a Patron
            </PatreonButton>
          </PatreonButtonWrapper>
        </PatreonLink>
      </div>
    </div>
  )
}
export default MockUp
